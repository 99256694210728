<template>
  <div class="mine">
    <div class="logo">
      <div class="banner-font">
        <div class="font-english">
          我们有最先进的职场防火墙，防扯皮、防内耗、防996、防不开心
        </div>
        <div class="font-chinese">树立运动榜样 引领全民健康生活</div>
      </div>
      <div class="font-download">
        <el-tooltip
          placement="top"
          effect="light"
          transition="el-zoom-in-center"
        >
          <div slot="content" style="width: 0.8rem; height: 0.8rem">
            <el-image :src="require('../assets/img/businessQrcode.png')"></el-image>
          </div>
          <div class="font-download-apple"></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          effect="light"
          transition="el-zoom-in-bottom"
        >
          <div slot="content" style="width: 0.8rem; height: 0.8rem">
            <el-image :src="require('../assets/img/businessQrcode.png')"></el-image>
          </div>
          <div class="font-download-android"></div>
        </el-tooltip>
      </div>
      <div class="mode"></div>
    </div>
    <div class="job">
      <div class="job-content">
        <div
          class="job-conten-first"
          v-for="(item, index) in options"
          :key="index"
          @click="handleActiveFirst(item, index)"
        >
          <div :class="{ activeFirst: activeFirst == index }">
            {{ item.label }}
          </div>
        </div>
      </div>
      <div v-if="secondArr.length > 0" class="job-conten-second">
        <div
          v-for="(item, index) in secondArr"
          :key="index"
          @click="handleActiveSecond(item, index)"
        >
          <div :class="{ activeSecond: activeSecond == index }">
            {{ item.label }}
          </div>
        </div>
      </div>
      <div v-if="threeArr.length > 0 && !isDetails" class="job-conten-three">
        <div
          class="job-conten-three-item"
          v-for="(item, index) in threeArr"
          :key="index"
          @click="handleJobDetails(item)"
        >
          <div class="item-html">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-info" v-html="item.label"></div>
          </div>
        </div>
      </div>
      <div   v-if="threeArr.length == 0 && !isDetails" class="notYet" >
            <span>暂 无 数 据</span>
      </div>
      <div
        class="job-conten-item-details"
        v-if="isDetails && JSON.stringify(jobDetails) != '{}'"
      >
        <div class="job-conten-item-details-left">
          <div class="job-conten-item-details-left-content">
            <div class="item-details-left-name">
              {{ jobDetails.name }}
            </div>
            <div class="description">职位描述：</div>
            <div v-html="jobDetails.description"></div>
            <div class="description">任职要求：</div>
            <div v-html="jobDetails.requirement"></div>
            <div class="item-details-btn">Email : hr@sportsofficer.xin</div>
          </div>
        </div>
        <div class="job-conten-item-details-right">
          <div class="job-conten-item-details-right-content">
            <div v-for="(item, index) in threeArr" :key="index">
              <div
                class="details-right-info"
                v-if="jobDetails.value != item.value"
                @click="handleJobDetails(item)"
              >
                <div class="info-html">
                  <div class="info-name">{{ item.name }}</div>
                  <div class="info-info" v-html="item.label"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="download">
      <div class="download-content">
        <div class="download-content-left">
          <div class="download-content-left-info">
            <div class="iconlogo">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_white.png')"
              ></el-image>
              <span class="iconlogotext">运动官</span>
            </div>
            <div class="download-content-left-introduce">
              <span>运动生活&nbsp;&nbsp;&nbsp;快乐有我<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
            <div class="iconlogo" style="margin-top: 0.8rem">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_black.png')"
              ></el-image>
              <span class="iconlogotext">运动官商家版</span>
            </div>
            <div class="download-content-left-introduce">
              <span>垂直体育分销系统<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="download-content-right">
          <div class="download-content-right-mode"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: "1",
          label: "青岛",
          children: [
            {
              value: "1-1",
              label: "研发",
              children: [
                {
                  value: "1-1-1",
                  name: "青岛 | Flutter移动开发工程师",
                  label:
                    "1、使用Flutter进行移动应用研发，能够独立完成iOS、Android应用的开发工作；<br/>2、热爱前端,精通flutter等前端开发技术，熟悉常用前端框架；",
                  description:
                    "1、使用Flutter进行移动应用研发，能够独立完成iOS、Android应用的开发工作；<br/>2、热爱前端,精通flutter等前端开发技术，熟悉常用前端框架；<br/>3、熟悉Flutter相关API及第三方框架；<br/>4、持续对产品开发流程进行改进与优化，提高开发能力和效率，并能通过技术提升用户体验和可用性。",
                  requirement:
                    "1、计算机相关专业背景，专科以上学历；<br/>2、5年及以上前端开发经验，有Flutter实际项目经验者优先；<br/>3、能够使用Flutter进行跨平台的移动端开发；<br/>4、熟悉iOS或安卓原生开发者优先；<br/>5、有丰富的Web标准、易用性、浏览器端原理以及网站性能优化等方面的知识。",
                },
                {
                  value: "1-1-2",
                  label:
                    "1、负责小程序的业务开发迭代，质量和体验优化；<br/>2、负责持续优化小程序性能，追求极致的用户体验；",
                  name: "青岛 | 小程序开发工程师",
                  description:
                    "1、负责小程序的业务开发迭代，质量和体验优化；<br/>2、负责持续优化小程序性能，追求极致的用户体验；<br/>3、关注前端方向前沿技术，跟踪新技术发展并运用到实际项目中。",
                  requirement:
                    "1、计算机相关专业背景，专科以上学历；<br/>2、3年以上的移动应用开发经验，具有独立或者主力完成开发项目的经验；<br/>3、熟练掌握uni-app框架独立完成小程序开发；<br/>4、有较强的产品理解，能从技术角度推动产品优化；思维缜密、思路清晰，较好的逻辑分析能力；<br/>5、开朗上进，积极沟通，善于团队协作。",
                },
                {
                  value: "1-1-3",
                  label:
                    "1、参与公司官网、系统管理员后台的前端开发，编写可复用的用户界面组件；<br/>2、持续优化前端体验和页面响应速度，并保证兼容性和执行效率；",
                  name: "青岛 | 前端开发工程师",
                  description:
                    "1、参与公司官网、系统管理员后台的前端开发，编写可复用的用户界面组件；<br/>2、持续优化前端体验和页面响应速度，并保证兼容性和执行效率；<br/>3、和后端工程师紧密配合，保证产品的用户体验及稳定性；<br/>4、具有较强的性能优化意识，并发挥积极作用任职要求。",
                  requirement:
                    "1、计算机相关专业背景，专科以上学历；<br/>2、3年以上前端开发经验，精通HTML/CSS/JavaScript等Web前端开发技术，深入掌握Ajax、DOM、BOM、XML、JSON等相关技术；<br/>3、具备跨浏览器、跨设备网页开发经验；<br/>4、熟练运用JavaScript语言与HTML5、CSS3等技术，熟悉Web绘图相关高级特性，如canvas，CSS3动画效果等；<br/>5、熟悉ES6标准、主流的JS库和开发框架，例如：Node.js、Vue、Webpack、angular，且有实际项目经验；<br/>6、熟悉微信小程序、跨平台APP和原生App开发者的优先。",
                }
              ],
            },
            {
              value: "1-2",
              label: "运营",
              children: [
                
              ],
            },
            {
              value: "1-3",
              label: "产品",
              children: [
                
              ],
            },
            {
              value: "1-4",
              label: "销售",
              children: [
               
              ],
            },
            {
              value: "1-5",
              label: "设计",
              children: [
                {
                  value: "1-5-1",
                  label:
                    "1、负责项目移动端和电脑端的、视觉设计，不断优化产品的交互流程和设计规范；<br/>2、与产品、技术及其他需求部门一起，从用户体验角度出发，进行互联网产品的设计，提升设计质量；",
                  name: "青岛 | 高级UI设计师",
                  description:
                    "1、负责项目移动端和电脑端的、视觉设计，不断优化产品的交互流程和设计规范；<br/>2、与产品、技术及其他需求部门一起，从用户体验角度出发，进行互联网产品的设计，提升设计质量；<br/>3、制定产品界面的风格及标准，包括界面控件、界面图标、交互设计；<br/>4、负责把控产品应用的各类UI设计规范、UI界面视觉风格；<br/>5、对产品用户体验、交互功能等进行研究，并提出建设性意见和改善方案。",
                  requirement:
                    "1、3年以上互联网产品设计经验，设计过跨多个不同平台产品的经验，熟悉web、 iOS、Android等不同平台的设计规范；<br/>2、美术设计类、广告学及相关专业，具有深厚的美术功底和良好的创意构思能力，对色彩有深刻的把握力、独特的风格、独到的创意视点与创新意识；<br/>3、熟练使用UI及平面设计软件、有一定手绘基础；<br/>4、熟悉互联网，热爱互联网产品，对互联网和移动互联网有比较综合的了解；<br/>5、善于表达和沟通，主动进行部门间的协同合作。主观能动性强，能够提出自己想法与思考推动项目进展。",
                },
              
              ],
            },
            {
              value: "1-6",
              label: "市场",
              children: [
               
              ],
            },
          ],
        }
        
      ],
      activeFirst: 0,
      secondArr: [],
      activeSecond: 0,
      threeArr: [],
      isDetails: false,
      jobDetails: {},
    };
  },
  methods: {
    handleActiveFirst(item, index) {
      // this.secondArr = []
      this.threeArr = [];
      this.activeFirst = index;
      this.secondArr = item.children;
      this.activeSecond = 0;
      this.threeArr = this.secondArr[0].children;
      this.isDetails = false;
    },
    handleActiveSecond(item, index) {
      this.threeArr = [];
      this.activeSecond = index;
      this.threeArr = item.children;
      this.isDetails = false;
    },
    handleJobDetails(item) {
      this.jobDetails = item;
      this.isDetails = true;
    },
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    this.secondArr = this.options[0].children;
    this.threeArr = this.secondArr[0].children;
  },
};
</script>

<style lang="less" scoped>
@keyframes fadenum {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.mine {
  width: 100%;
  .logo {
    width: 100%;
    height: 5.5rem;
    // height: 100%;
    position: relative;
    text-align: center;
    // background-size:contain;
    background: url("../assets/img/bangong.jpg") no-repeat;
    background-size: 100% 100%;
    .mode {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #409eff;
      opacity: 0.3;
      z-index: 1;
    }
  }
  .font-download {
    position: absolute;
    width: 2rem;
    // height: 0.3rem;
    // background-color: red;
    bottom: 0.4rem;
    right: 0.6rem;
    z-index: 2;
    // bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    .font-download-apple {
      width: 0.9rem;
      height: 0.25rem;
      background: url("../assets/img/u912.png") no-repeat center;
      background-size: 80% 80%;
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: #111;
      cursor: pointer;
    }
    .font-download-android {
      width: 0.9rem;
      height: 0.25rem;
      background: url("../assets/img/u913.png") no-repeat center;
      background-size: 80% 80%;
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: #111;
      cursor: pointer;
    }
  }

  .banner-font {
    position: absolute;
    top: 40%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 2;
    .font-english {
      font-weight: 600;
      font-size: 0.17rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-chinese {
      margin-top: 0.2rem;
      font-size: 0.13rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-behavior {
      width: 2.5rem;
      height: 0.26rem;
      // background-color: red;
      margin: 1rem auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 0.26rem;
      .font-understand {
        height: 100%;
        padding: 0 0.25rem;
        font-size: 0.08rem;
        background-color: #111;
        border: 1px solid #fff;
        border-radius: 10px;
        cursor: pointer;
      }
      .font-join {
        height: 100%;
        padding: 0 0.25rem;
        font-size: 0.08rem;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid #fff;
        border-radius: 10px;
      }
      .font-join:hover {
        background-color: #111;
      }
    }
  }
  .job {
    width: 100%;
    height: 100%;
    min-height: 3rem;
    cursor: pointer;
    .job-content {
      display: flex;
      margin: 0.5rem 0.5rem 0.2rem;
      font-size: 0.1rem;
      .job-conten-first {
        // width: 100px;
        padding-right: 0.4rem;
        .activeFirst {
          color: #333;
          font-weight: 600;
        }
      }
    }
    .job-conten-second {
      display: flex;
      margin: 0rem 0.5rem;
      font-size: 0.1rem;
      > div {
        padding-right: 0.4rem;
      }
      .activeSecond {
        color: #333;
        font-weight: 600;
      }
    }
    .job-conten-three {
      display: flex;
      // height: 1rem;
      margin: 0rem 0.5rem;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      .job-conten-three-item {
        width: 45%;
        margin: 0.2rem 0;
        background-color: #f5f5f5;
        .item-html {
          margin: 0.2rem;
          line-height: 28px;
        }
        .item-name {
          margin-bottom: 0.08rem;
          font-size: 0.1rem;
          font-weight: 600;
        }
        .item-info {
          font-size: 0.08rem;
        }
      }
    }
    .notYet{
      display: flex;
      min-height: 2rem;
      align-items: center;
      margin:0 0.5rem;
      text-align:center;
      font-size:0.12rem;
      span{
        width: 100%;
      }
    }
    .job-conten-item-details {
      width: 100%;
      // height: 5rem;
      display: flex;
      .job-conten-item-details-left {
        width: 6rem;
        border-right: 1px solid #111;
        .job-conten-item-details-left-content {
          margin: 0.2rem 0.5rem;
          line-height: 48px;
          font-size: 0.07rem;
          .item-details-left-name {
            font-size: 0.14rem;
            font-weight: 600;
          }
          .description {
            font-size: 0.09rem;
            margin-top: 0.2rem;
          }
          .item-details-btn {
            margin-top: 0.2rem;
            width: 2rem;
            height: 0.24rem;
            text-align: center;
            line-height: 0.24rem;
            background-color: rgba(64, 158, 255);
            border-radius: 10px;
            color: #fff;
          }
        }
      }
      .job-conten-item-details-right {
        flex: 1;
        .job-conten-item-details-right-content {
          margin: 0 0.5rem;
          // line-height:48px;
          // font-size: 0.07rem;
          .details-right-info {
            margin-bottom: 0.2rem;
            background-color: #f5f5f5;

            .info-html {
              padding: 0.2rem;
              line-height: 28px;
            }
            .info-name {
              margin-bottom: 0.08rem;
              font-size: 0.1rem;
              font-weight: 600;
            }
            .info-info {
              font-size: 0.07rem;
            }
          }
        }
      }
    }
  }
  .download {
    width: 100%;  
    // height: 4.5rem;
    background-color: rgba(34, 34, 34, 1);
    .download-content {
      display: flex;
      // height: 100%;
      .download-content-left {
        width: 3rem;
        // height: 100%;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        background-color: rgba(34, 34, 34, 1);
        .download-content-left-info {
          width: 2rem;
          // height: 3.5rem;
          margin: 0 auto;
          background-color: rgba(34, 34, 34, 1);
          color: #fff;
          .iconlogo {
            display: flex;
            align-items: center;
            .iconlogoimg {
              width: 0.15rem;
              height: 0.15rem;
            }
            .iconlogotext {
              font-size: 0.11rem;
              color: #fff;
              margin-left: 0.04rem;
            }
          }
          .download-content-left-introduce {
            margin-top: 0.15rem;
            font-size: 0.07rem;
            line-height: 28px;
          }
          .download-content-left-mode {
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .download-content-left-mode-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .download-content-left-mode-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
          }
        }
      }
      .download-content-right {
        position: relative;
        flex: 1;
        background: url("../assets/img/haibian.jpg") no-repeat;
        background-size: 100% 100%;
        .download-content-right-mode {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #111;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>